import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useMember, useUpdateMember } from 'shared-ui/components/pages/PageWrapper'
import io from 'socket.io-client'
import { useApi } from 'shared-ui/api/ApiContext'
import useEvent from 'react-use-event-hook'

const WebsocketContext = createContext({
  socket: null,
  jpointsChanges: [],
  setJpointsChanges: () => {},
})

const apiUrl = process.env.REACT_APP_API_URL

export default function WebsocketEvents({ children }) {
  const member = useMember()
  const api = useApi()
  const updateMember = useUpdateMember()
  const [jpointsChanges, setJpointsChanges] = useState([])
  const updateMergedMember = useEvent(data => {
    updateMember?.({ ...member, ...data })
  })

  const onConnect = useEvent(() => {
    socket.emit('join', member.socketChannel)
    console.log('connected to websocket ...')
  })
  const onUpdateJPoints = useEvent(newPointsArray => {
    const previousPointsArray = member?.jpoints || []

    const previousPointsMap = Object.fromEntries(previousPointsArray.map(p => [p.id, p.amount]))

    const differences = newPointsArray
      .map(newPoint => {
        const prevAmount = previousPointsMap[newPoint.id] || 0
        const difference = newPoint.amount - prevAmount

        if (difference !== 0) {
          return {
            id: newPoint.id,
            type: newPoint.description,
            difference,
            color: newPoint.color,
            newAmount: newPoint.amount,
          }
        }

        return null
      })
      .filter(Boolean)

    if (differences.length > 0) {
      setJpointsChanges(prev => [...prev, ...differences])
    }

    updateMergedMember({ jpoints: newPointsArray })
  })

  const onUpdateLoyaltyTier = useEvent(tier => {
    const { tier: currentTier } = member
    if (currentTier && currentTier._id === tier._id) return
    updateMergedMember({ tier })
  })

  const onUpdateNumCartItems = useEvent(numItems => {
    const { numCartItems } = member
    if (numCartItems === numItems) return
    updateMergedMember({ numCartItems: numItems })
  })

  const onNavigateTo = useEvent(href => {
    api.connector.onNavigateTo(href)
  })

  const onShowMessage = useEvent(msg => {
    const { Description } = msg
    console.log(msg)
    if (window.confirm(Description)) api.connector.onNavigateTo('/agreements')
  })

  const socket = useMemo(() => {
    if (!member?.socketChannel) return null
    const res = /^(https?:\/\/)?(.*?)\/(.+)$/gi.exec(apiUrl)
    return io(res[2] || '/', { path: `/${res[3]}/socket`, transports: ['websocket'] })
  }, [member?.socketChannel])

  useEffect(() => {
    if (!socket) return
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && !socket.connected) {
        socket.connect()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket.once('connect', onConnect)
    socket.on('updateJPoints', onUpdateJPoints)
    socket.on('updateLoyaltyTier', onUpdateLoyaltyTier)
    socket.on('updateNumCartItems', onUpdateNumCartItems)
    socket.on('navigateTo', onNavigateTo)
    socket.on('showMessage', onShowMessage) // C360 hardcoded demo stuff.
    socket.on('disconnect', () => {
      console.log('disconnected websocket ...')
    })
  }, [socket])

  return (
    <WebsocketContext.Provider value={{ socket, jpointsChanges, setJpointsChanges }}>
      {children}
    </WebsocketContext.Provider>
  )
}

export const useWebsocket = () => useContext(WebsocketContext)
