import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { withApi } from 'shared-ui/api/ApiContext'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { ReactComponent as Logo } from 'shared-ui/assets/img/logo-black.svg'
import HeaderItem from '~components/molecules/HeaderItem'
import HeaderItemDropDown from '~components/molecules/HeaderItemDropDown'
import HeaderItemOrders from '~components/molecules/HeaderItemOrders'
import HeaderItemNotificationCenter from '~components/molecules/HeaderItemNotificationCenter'
import Typography from '@material-ui/core/Typography'
import Link from 'shared-ui/components/Link'
import HeaderItemPoints from '~components/molecules/HeaderItemPoints'
import HeaderItemLoyalty from '~components/molecules/HeaderItemLoyalty'
import AppMode from '~components/organisms/AppMode'
import classnames from 'classnames'
import HeaderItemCash from '~components/molecules/HeaderItemCash'
import HealthWarning from '~components/molecules/HealthWarning'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import useEvent from 'react-use-event-hook'
import { useThemeUpdate } from 'shared-ui/assets/styles/PortalThemeProvider'
import EnvBar from 'shared-ui/components/atoms/EnvBar'
import { useAppFrame } from 'shared-ui/components/AppFrameContext'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const components = {
  HeaderItem,
  HeaderItemPoints,
  HeaderItemLoyalty,
  HeaderItemDropDown,
  HeaderItemCash,
  HeaderItemOrders,
  HeaderItemNotificationCenter,
}

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  item: {
    minWidth: unit * 7 - 4,
    height: '100%',
    paddingBottom: 2,
    '&:hover': {
      paddingBottom: 0,
      borderBottom: `2px solid ${palette.accent400.main}`,
      marginBottom: 2,
    },
    [breakpoints.down('sm')]: {
      minWidth: unit * 4 - 4,
    },
  },
  platformIcon: {
    position: 'relative',
    maxWidth: '100%',
    maxHeight: '100%',
    [breakpoints.down('xs')]: {
      maxHeight: '86%',
    },
  },
})

const Header = withStyles(styles)(props => {
  const {
    classes,
    renderDrawerIcon,
    member,
    languages,
    mode,
    data: { items = [] },
    id,
  } = props
  const appFrame = useAppFrame()
  const { healthWarningFooter } = appFrame.settings.general

  const [scrolled, setScrolled] = useState(false)

  const { colorScheme, setColorScheme } = useThemeUpdate()
  const isDark = colorScheme === 'dark'
  const iconName = isDark ? 'nightlight' : 'light_mode'

  const handleScroll = useEvent(() => {
    if (!scrolled) {
      if (window.scrollY > 10) {
        setScrolled(true)
      }
    } else if (window.scrollY === 0) {
      setScrolled(false)
    }
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const handleLanguageChange = useEvent(e => {
    const lang = e.target.value
    window.localStorage.setItem('lang', lang)
    window.location.reload()
  })

  const handleSwitchScheme = useEvent(e => {
    setColorScheme(isDark ? 'light' : 'dark')
  })

  const platformName = appFrame?.settings?.general?.platformName ?? ''
  const platformIcon = appFrame?.settings?.general?.platformIcon
  const color = appFrame?.env?.color
  const ltrStyle = platformIcon ? { textAlign: 'left' } : { flip: false, direction: 'ltr' }
  const linkClass = classnames(
    'relative self-start flex me-1',
    platformIcon && 'h-full max-w-[188px] items-center block',
  )

  return (
    <AppBar
      id={id}
      position="sticky"
      className={`!text-grey-500 !bg-paper !px-0 !transition-shadow ${scrolled ? '!shadow-md' : ''}`}
    >
      {color && <EnvBar color={color} />}

      {mode && <AppMode {...mode} />}

      <div className="relative z-[3] m-auto flex h-16 w-full pl-4 md:pl-0">
        {renderDrawerIcon && renderDrawerIcon()}

        <Toolbar className="grow justify-between" disableGutters>
          <Link to={'/'} className={linkClass} style={ltrStyle}>
            {platformIcon ? (
              <img src={platformIcon.path} alt="Platform Icon" className={classes.platformIcon} />
            ) : (
              <React.Fragment>
                <Logo />
                {platformName && (
                  <Typography className="b-0 self-end" variant={'caption'}>
                    {platformName.toUpperCase()}
                  </Typography>
                )}
              </React.Fragment>
            )}
          </Link>

          <div className="flex h-full justify-end">
            {items.map(({ component, props, _link }, index) => {
              const Component = components[component]

              if (!Component) return null

              return (
                <Component
                  key={'item_' + index}
                  {...props}
                  _link={_link}
                  member={member}
                  className={classes.item}
                />
              )
            })}

            {!member && languages.length > 1 && (
              <Select
                className="self-center"
                value={IntlUtil.locale.slice(0, 2)}
                onChange={handleLanguageChange}
              >
                {languages.map(({ id, label, labelShort }) => (
                  <MenuItem key={id} value={id}>
                    {`${labelShort} ${label}`}
                  </MenuItem>
                ))}
              </Select>
            )}

            <div className="flex flex-col items-center self-end p-2 pb-[8px]">
              <IconButton onClick={handleSwitchScheme}>
                <Icon color={'primary'}>{iconName}</Icon>
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </div>
      <HealthWarning />
    </AppBar>
  )
})

export default withApi(Header)
