import { useMember } from 'shared-ui/components/pages/PageWrapper'
import useEvent from 'react-use-event-hook'
import { useApi } from 'shared-ui/api/ApiContext'
import useEffectOnce from 'shared-ui/hooks/useEffectOnce'
import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'
import { useAppFrame } from 'shared-ui/components/AppFrameContext'
import { initializeApp } from 'firebase/app'
import {
  MessagePayload,
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from 'firebase/messaging'

export default function GoogleServices() {
  const appFrame = useAppFrame()
  const member = useMember()
  const api = useApi()

  const technical = appFrame?.settings?.technical
  const language = appFrame?.settings?.language

  // Google Analytics
  const initGAEvent = useEvent(() => {
    AnalyticsEvents.init(
      technical.gtmId,
      technical.gtmSettings,
      language.currency,
      technical.addTags,
    )
  })
  useEffectOnce(() => {
    if (!technical?.gtmId) return false
    initGAEvent()
  }, [technical?.gtmId, initGAEvent])

  // Firebase Web Notifications
  const firebaseClient = technical?.firebaseClient
  const vapidKey = technical?.firebaseVapidKey

  const initFirebaseEvent = useEvent(() => {
    void initialiseFirebase(api, { ...firebaseClient, vapidKey })
  })

  useEffectOnce(() => {
    // console.log('Firebase Setup ', member?._id, firebaseClient)
    if (!member || !firebaseClient) return false
    initFirebaseEvent()
  }, [member, firebaseClient, initFirebaseEvent])

  return null
}

async function initialiseFirebase(api, firebaseClient) {
  const { vapidKey } = firebaseClient
  // console.log('Firebase initialiseFirebase ', firebaseClient, vapidKey)
  if (!vapidKey || !firebaseClient || !firebaseClient?.projectId) return

  initializeApp(firebaseClient)
  const isSup = await isSupported()

  if (!isSup) return console.warn('Push notifications are not supported in this browser')

  const messaging = getMessaging()
  const serviceWorkerRegistration = await navigator.serviceWorker
    .register(`v1/serviceworker.js`)
    .catch(console.error)

  const permission = await Notification.requestPermission()
  if (permission !== 'granted') return console.log('Permission denied for notifications.')

  const currentToken = await getToken(messaging, { serviceWorkerRegistration, vapidKey }).catch(
    err => console.log('Error fetching token', err),
  )

  if (!currentToken) return console.log('Token Error.')

  onMessage(messaging, payload => {
    console.log('payload message', payload)
    const {
      notification: { body, title },
      data,
    } = payload

    // Optionally, display a custom notification
    if (Notification.permission === 'granted') {
      const { title, body, icon } = payload.notification
      new Notification(title, {
        body,
        icon: icon || '/jti-icon.png', // Optional fallback icon
      })
    }

    api.connector.dispatchAlert({
      message: `${title} - ${body}`,
      variant: 'info',
      dontHide: true,
      customAction: data && data.slug,
    })
  })
  void api.registerToken(currentToken)
}
