import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useAppFrame } from 'shared-ui/components/AppFrameContext'
import PortalLabels from '~src/constants/PortalLabels'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import { PANEL_HEIGHT } from '~components/molecules/OrdersModule/components/CheckoutPanel'

const FooterNavigation = forwardRef(
  ({ items, showMobileMenu, toggleMobileMenu, onFooterPosition }, ref) => {
    const appFrame = useAppFrame()
    const { healthWarningFooter, healthWarningSize, displayHealthWarning } =
      appFrame.settings.general
    const localRef = useRef(null)
    const targetRef = ref || localRef

    useEffect(() => {
      if (!targetRef.current) return

      let footerHeight = targetRef.current.offsetHeight || 0

      // Add health warning height if applicable
      if (healthWarningFooter && displayHealthWarning) {
        const healthWarningPx = (window.innerHeight * healthWarningSize) / 100
        footerHeight += healthWarningPx
      }

      // Select elements inside useEffect to get latest elements
      const checkoutPanel$ = document.querySelector('#CheckoutPanel')
      const badge$ = document.querySelector('.grecaptcha-badge')
      const chatbot$ = document.querySelector('#Chatbot')

      // Update CheckoutPanel position dynamically
      if (checkoutPanel$) {
        footerHeight += parseInt(PANEL_HEIGHT, 10) * 2
      }

      // Update position and transition for badge & chatbot
      const updatePosition = element => {
        if (element) {
          element.style.setProperty('transform', `translateY(-${footerHeight}px)`)
          element.style.setProperty('z-index', '10', 'important')
          element.style.setProperty('transition', 'all 0.5s ease-in-out')
        }
      }

      updatePosition(badge$)
      updatePosition(chatbot$)

      // Update visibility based on `showMobileMenu`
      const updateVisibility = (element, isVisible) => {
        if (element) {
          element.style.transition = 'opacity 0.3s ease-in-out'

          if (isVisible) {
            setTimeout(() => {
              element.style.display = 'block'
              setTimeout(() => (element.style.opacity = '1'), 10) // Ensures transition applies
            }, 100) // Delay before appearing
          } else {
            element.style.opacity = '0'
            setTimeout(() => (element.style.display = 'none'), 100) // Wait for fade-out to complete
          }
        }
      }

      updateVisibility(badge$, !showMobileMenu)
      updateVisibility(chatbot$, !showMobileMenu)

      // Update footer position for layout purposes
      const updateFooterPosition = () => {
        if (targetRef.current) {
          const footerPosition = targetRef.current.getBoundingClientRect().top
          onFooterPosition(footerPosition)
        }
      }

      setTimeout(updateFooterPosition, 50)
    }, [
      ref,
      onFooterPosition,
      healthWarningFooter,
      healthWarningSize,
      displayHealthWarning,
      showMobileMenu,
    ])

    return (
      <nav
        id="footerNavbar"
        ref={ref || localRef}
        className="fixed bottom-0 left-0 right-0 z-[9999] w-full sm:hidden"
        style={{
          bottom: healthWarningFooter && displayHealthWarning ? `${healthWarningSize}vh` : '0',
        }}
      >
        <div className="bg-paper border-accent-400 relative w-full border-t px-4 shadow-md">
          <ul className="flex w-full justify-between">
            {items.map((item, index) => (
              <li key={index} className="flex flex-1 flex-col items-center justify-center">
                <button
                  onClick={() => (window.location.href = item.props.to)}
                  className="flex flex-col items-center"
                >
                  <div className="hover:bg-accent-200 rounded-button flex h-10 w-10 items-center justify-center">
                    <span className="material-icons">{item.props.icon}</span>
                  </div>
                  <div className="flex min-h-[2rem] flex-col justify-start text-center text-xs leading-tight">
                    <span className="block text-center">{item.props.label}</span>
                  </div>
                </button>
              </li>
            ))}

            <li className="flex flex-1 flex-col items-center justify-center">
              <button onClick={toggleMobileMenu} className="flex flex-col items-center">
                <div className="hover:bg-accent-200 rounded-button flex h-10 w-10 items-center justify-center">
                  <span className="material-icons">{showMobileMenu ? 'close' : 'menu'}</span>
                </div>
                <div className="flex min-h-[2rem] flex-col justify-start text-center text-xs leading-tight">
                  <span className="block text-center">
                    {showMobileMenu
                      ? IntlUtil.label(PortalLabels.FOOTER_NAV_CLOSE_MENU_LABEL)
                      : IntlUtil.label(PortalLabels.FOOTER_NAV_SHOW_MORE_LABEL)}
                  </span>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </nav>
    )
  },
)

export default FooterNavigation
