import PopupHeader from 'shared-ui/components/popup/PopupHeader'
import ImageBase from '~components/atoms/ImageBase/ImageBase'
import PopupFooter from 'shared-ui/components/popup/PopupFooter'
import PopupBox from 'shared-ui/components/popup/PopupBox'
import { Typography } from '@material-ui/core'
import Popup from 'shared-ui/components/organisms/Popup'
import ScrollFade from '~components/molecules/ScrollFade'
import PopupContent from 'shared-ui/components/popup/PopupContent'

const GenericPopupTemplate = ({ children, header, open, onClose, footer }) => {
  return (
    <Popup open={open} onClose={onClose} disableBackdropClick>
      <PopupBox onClose={onClose}>
        <PopupHeader>
          <div>
            <Typography style={{}} variant="h4">
              {header || 'test'}
            </Typography>
          </div>
        </PopupHeader>
        <PopupContent>
          <ScrollFade>{children}</ScrollFade>
        </PopupContent>
        {footer && <PopupFooter className={'flex justify-end'}>{footer}</PopupFooter>}
      </PopupBox>
    </Popup>
  )
}
export default GenericPopupTemplate
