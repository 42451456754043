import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { withApi } from 'shared-ui/api/ApiContext'

const SnackMessages = ({ api: { connector } = {} }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    let errorSubscription

    if (connector) {
      errorSubscription = connector.listenToAlerts(onAlert)
    }

    return () => {
      if (errorSubscription) {
        errorSubscription.unsubscribe()
      }
    }
  }, [connector])

  const onAlert = alert => {
    const { message, stack, variant = 'error', dontHide = false } = alert

    if (!message) return

    closeSnackbar()

    enqueueSnackbar(`${message}${stack ? `: ${stack}` : ''}`, {
      variant,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: dontHide ? null : 6000,
    })
  }

  return null
}

export default withApi(SnackMessages)
