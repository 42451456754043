import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import { useThemeUpdate } from 'shared-ui/assets/styles/PortalThemeProvider'

const styles = ({ breakpoints, direction }) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  paperBoxFadeEndVertical: {
    overflowY: 'scroll',
    bottom: 0,
    right: 0,
    left: 0,
    paddingRight: '1rem',
    height: '100%',
    '&:after': {
      opacity: '1',
      width: '100%',
    },
  },
  paperBoxVertical: {
    overflowY: 'scroll',
    height: '100%',
    paddingRight: '1rem',
    '&:after': {
      opacity: '1',
      transition: 'opacity 200ms',
      content: '""',
      position: 'absolute',
      height: '25%',
      right: 0,
      bottom: 0,
      width: '100%',
      background:
        'linear-gradient(to bottom, rgba(255,255,255,0) 0%,  rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,1) 100%)',
    },
  },
  paperBox: {
    overflow: 'auto',
    [breakpoints.down('xs')]: {
      '&:after': {
        opacity: '0',
        transition: 'opacity 200ms',
        content: '""',
        position: 'absolute',
        height: '100%',
        right: 0,
        bottom: 0,
        width: 0,
        background:
          direction === 'ltr'
            ? 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.9) 80%, rgba(255,255,255,1) 100%)'
            : 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 20%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
      },
    },
  },
  paperBoxDark: {
    overflow: 'auto',
    [breakpoints.down('xs')]: {
      '&:after': {
        opacity: '0',
        transition: 'opacity 200ms',
        content: '""',
        position: 'absolute',
        height: '100%',
        right: 0,
        bottom: 0,
        width: 0,
        background:
          direction === 'ltr'
            ? 'linear-gradient(90deg, rgba(25,27,41,0) 0%, rgba(25,27,41,0.5) 50%, rgba(25,27,41,0.9) 80%, rgba(25,27,41,1) 100%)'
            : 'linear-gradient(90deg, rgba(25,27,41,1) 0%, rgba(25,27,41,0.9) 20%, rgba(25,27,41,0.5) 50%, rgba(25,27,41,0) 100%)',
      },
    },
  },
  paperBoxFadeEnd: {
    [breakpoints.down('xs')]: {
      '&:after': {
        opacity: '1',
        width: '25%',
      },
    },
  },
})

const ScrollFade = ({ classes, className, style, children, vertical, theme }) => {
  const scrollFadeRef = useRef(null)
  const [fadeTableClass, setFadeTableClass] = useState('paperBoxFadeEnd')
  const [verticalFadeClass, setVerticalFadeClass] = useState('paperBoxVertical')
  const { colorScheme } = useThemeUpdate()
  useEffect(() => {
    handleScroll()
  }, [])

  const handleScroll = e => {
    const element = scrollFadeRef.current || e?.currentTarget
    if (!element) return

    const scrollMaxWidth = element.scrollWidth - element.clientWidth
    const scrollMaxHeight = element.scrollHeight - element.clientHeight - 3
    const direction = theme?.direction || 'ltr'

    const newVerticalFadeClass =
      element.scrollTop >= scrollMaxHeight ? 'paperBoxFadeEndVertical' : 'paperBoxVertical'
    const newFadeTableClass =
      direction === 'ltr'
        ? element.scrollLeft >= scrollMaxWidth
          ? ''
          : 'paperBoxFadeEnd'
        : element.scrollLeft <= 0
          ? ''
          : 'paperBoxFadeEnd'

    if (verticalFadeClass !== newVerticalFadeClass) setVerticalFadeClass(newVerticalFadeClass)
    if (fadeTableClass !== newFadeTableClass) setFadeTableClass(newFadeTableClass)
  }

  return (
    <div className={classes.root}>
      <div
        ref={scrollFadeRef}
        className={classNames(className, {
          [classes.paperBox]: !vertical,
          [classes.paperBoxDark]: !vertical && colorScheme === 'dark',
          [classes[fadeTableClass]]: !vertical,
          [classes[verticalFadeClass]]: vertical,
        })}
        style={style}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(ScrollFade)
