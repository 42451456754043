import React, { useState, useEffect, forwardRef } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import clsx from 'classnames'

const variantStyles = {
  success: 'bg-green',
  error: 'bg-red text-persistent-white',
  warning: 'bg-orange text-persistent-black',
  info: 'bg-grey-200',
}

const iconMapping = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error',
  info: 'info',
}

const CustomSnackBar = forwardRef(
  ({ message, variant, autoHideDuration = 6000, id, onClose }, ref) => {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
      const hideTimer = setTimeout(() => {
        handleClose()
      }, autoHideDuration)

      return () => {
        clearTimeout(hideTimer)
      }
    }, [autoHideDuration])

    const handleClose = () => {
      setVisible(false)
      setTimeout(() => {
        onClose && onClose(id)
      }, 0) // Remove instantly
    }

    if (!visible) return <div ref={ref} className="sr-only" />

    return (
      <div
        ref={ref}
        className={clsx(
          'rounded-button border-grey-300 mt-8 flex items-center justify-between border px-4 py-3 pl-5 pr-5 shadow-md md:mt-14',
          variantStyles[variant],
        )}
      >
        <div className="flex items-center space-x-2">
          <Icon className="h-5 w-5">{iconMapping[variant]}</Icon>
          <span className="text-base md:text-sm">{message}</span>
        </div>

        <IconButton aria-label="Close" color="inherit" onClick={handleClose}>
          <Icon>close</Icon>
        </IconButton>
      </div>
    )
  },
)

export default CustomSnackBar
