import { useState, useEffect } from 'react'

const useDeviceType = () => {
  const getDeviceType = () => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent,
      ) || window.innerWidth <= 768

    const isMobileLandScape = isMobile && window.innerWidth > window.innerHeight

    return { isMobile, isMobileLandScape }
  }

  const [device, setDevice] = useState(getDeviceType())

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDeviceType())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return device
}

export default useDeviceType
