import React from 'react'
import FormElementsGroup from 'shared-ui/components/organisms/FormElementsGroup/index'

export default class SettingEditor extends React.Component {
  render() {
    const { elements, edit, editorRef } = this.props

    return elements && edit ? (
      <FormElementsGroup innerRef={editorRef} onChange elements={elements} />
    ) : (
      this.props.render()
    )
  }
}
