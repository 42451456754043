import React, { useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { withAccount } from '~src/AccountContext'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Popover from '@material-ui/core/Popover'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'
import { IconButton, Paper } from '@material-ui/core'
import GenericPopupTemplate from '~components/templates/GenericPopupTemplate'
import SettingsBox from '~components/organisms/SettingsBox'

const styles = ({ spacing: { unit }, palette }) => ({
  root: {
    '&:hover': {
      borderBottomColor: 'transparent !important',
    },
  },

  select: {
    maxWidth: unit * 20,
    fontSize: '0.9em',
    lineHeight: '1em',
    height: '100%',
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
    width: '100%',
  },

  selectSelect: {
    minHeight: unit * 2,
    paddingLeft: unit,
    display: 'flex',
    alignItems: 'center',
  },

  greyBackground: {
    '&:hover': {
      backgroundColor: palette.grey200,
    },
    backgroundColor: palette.grey100,
  },
  brandBackground: {
    '&:hover': {
      backgroundColor: palette.accent300.main,
    },
    backgroundColor: palette.accent300.main,
  },

  selectMenu: {
    whiteSpace: 'normal',
  },

  paper: {
    maxHeight: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    maxWidth: unit * 50,
  },

  itemsContainer: {
    height: '100%',
    overflowY: 'auto',
    '-ms-overflow-style': 'scrollbar',
    '&>*': {
      display: 'block',
      boxSizing: 'border-box',
      height: 'auto',
      '@media (max-width: 400px)': {
        fontSize: '3vw',
      },
    },
  },
  fadeOverlay: {
    overflow: 'auto',
    '&:after': {
      opacity: '1',
      transition: 'opacity 200ms',
      content: '""',
      position: 'absolute',
      height: '25%',
      right: 0,
      bottom: 0,
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(255,255,255,1) 0%,  rgba(255,255,255,0.9) 20%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%)',
    },
  },
  fadeOverlayDisable: {
    '&:after': {
      opacity: 0,
      width: '0',
      height: '0',
    },
  },

  hoverPopover: {
    pointerEvents: 'none',
  },

  hoverPaper: {
    padding: unit,
    color: palette.paper,
    backgroundColor: palette.grey400,
    '& p': {
      color: palette.paper,
      '@media (max-width: 400px)': {
        fontSize: '3vw',
      },
    },
  },
  name: {
    '@media (max-width: 400px)': {
      fontSize: '3vw',
    },
  },

  icon: {
    fill: palette.accent300.contrastText,
  },

  label: {
    color: palette.accent300.contrastText,
  },
})

class HeaderItemDropDown extends React.Component {
  scrollFade = React.createRef()
  state = {
    inputValue: '',
    selectedItem: [],
    isLoading: false,
    popperOpen: false,
    scrolledToEnd: false,
  }

  getSuggestions(inputValue) {
    if (this.props.accounts === null) {
      return null
    }

    let { accounts = [] } = this.props

    let matchedGroupsById = {}
    let matchedAccountGroupsByName = {}

    let result = []

    accounts.forEach(account => {
      const { accountName, groupName, groupId, merged } = account

      if (matchedGroupsById[groupId] && merged === false) {
        result.push(account)
        return
      }

      if (groupName && groupName.toLowerCase().includes(inputValue.toLowerCase())) {
        if (merged === true) {
          if (!matchedGroupsById[groupId]) {
            result.push({ ...account, name: groupName })
          }
        } else {
          result.push({ name: groupName }, account)
        }
        matchedGroupsById[groupId] = true

        return
      }

      if (!merged && accountName.toLowerCase().includes(inputValue.toLowerCase())) {
        if (groupId && !matchedAccountGroupsByName[groupId]) {
          matchedAccountGroupsByName[groupId] = groupId
          result.push({ name: groupName })
        }

        result.push(account)
      }
    })

    return result
  }

  handleClose = () => {
    this.setState({
      popperOpen: false,
    })
  }

  _handleSuggestionClick = suggestion => () => {
    this.setState({
      popperOpen: false,
    })

    const { onAccountChange } = this.props
    onAccountChange && onAccountChange(suggestion._id)
  }

  _handleInputChange = ({ target: { value } }) => {
    this.setState({ inputValue: value })
  }

  handleMouseEnter = event => {
    this.setState({ hoverEl: event.currentTarget })
  }

  handleMouseLeave = () => {
    this.setState({ hoverEl: null })
  }

  handleScroll = e => {
    const element = this.scrollFade.current || e.currentTarget
    const scrollMax = element.scrollHeight - element.clientHeight
    const { scrolledToEnd } = this.state
    let newScrolledToEnd = element.scrollTop >= scrollMax
    if (scrolledToEnd !== newScrolledToEnd) this.setState({ scrolledToEnd: newScrolledToEnd })
  }

  render() {
    const {
      classes,
      className,
      accounts = [],
      selectedAccountId,
      grey,
      gridView,
      onAccountChange,
      labels,
      storeSettingsItems = [],
    } = this.props

    let selectedAccount = accounts.find(({ _id }) => _id === selectedAccountId)

    const { popperOpen, inputValue, hoverEl, scrolledToEnd, popupOpen } = this.state

    const suggestions = this.getSuggestions(inputValue)
    const name =
      selectedAccount.merged === true ? selectedAccount.groupName : selectedAccount.accountCode
    const hoverAccountName =
      selectedAccount.merged === true ? null : (
        <React.Fragment>
          <b>{selectedAccount.accountName}</b> /
        </React.Fragment>
      )

    const account = accounts.find(({ _id }) => _id === selectedAccountId)

    if (gridView)
      return (
        <>
          {popupOpen && (
            <GenericPopupTemplate
              open={popupOpen}
              onClose={() => this.setState({ popupOpen: false })}
              header={labels.storeInformation}
            >
              <div className={'flex flex-col'}>
                <div className={'m-6'}>
                  <div className={'bg-grey-100 p-6'}>
                    {Object.entries(account).map(([key, value]) => {
                      if (
                        value === null ||
                        value === undefined ||
                        typeof value === 'object' ||
                        key === '_id'
                      )
                        return null

                      const item = storeSettingsItems.find(x => x.id === key)
                      if (!item?.enabled) return null
                      return (
                        <div
                          key={key}
                          className="border-b-grey-200 flex justify-between border-b-2 py-2"
                        >
                          <span className="text-grey-400 font-semibold">{value?.toString()}</span>
                          <span className="text-grey-400 m-w text-end">
                            {item?.name || key}
                            {/*{key}*/}
                          </span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </GenericPopupTemplate>
          )}
          <AccountsGridView
            labels={this.props.labels}
            accounts={accounts}
            selectedAccountId={selectedAccountId}
            onAccountChange={onAccountChange}
            onMoreClicked={() => this.setState({ popupOpen: true })}
          />
        </>
      )

    return (
      <React.Fragment>
        <div
          className={classNames(classes.root, className)}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={ref => (this.popperNode = ref)}
          style={{ cursor: 'pointer', height: '100%' }}
          onClick={() => {
            this.setState({ popperOpen: !popperOpen })
          }}
        >
          <Select
            style={{ pointerEvents: 'none' }}
            classes={{
              icon: classes.icon,
              selectMenu: classes.selectMenu,
              select: classNames(
                classes.selectSelect,
                grey ? classes.greyBackground : classes.brandBackground,
              ),
            }}
            className={classes.select}
            value={selectedAccountId}
            renderValue={() => (
              <div className="flex items-center">
                <Typography
                  className={classNames('!line-clamp-2 whitespace-nowrap', classes.label)}
                >
                  {name}
                </Typography>
              </div>
            )}
          />
        </div>

        <Popover
          className={classes.hoverPopover}
          classes={{
            paper: classes.hoverPaper,
          }}
          open={!!hoverEl}
          anchorEl={hoverEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={this.handleMouseLeave}
          disableRestoreFocus
        >
          <Typography>
            {hoverAccountName}
            {selectedAccount.groupName}
          </Typography>
        </Popover>

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={popperOpen}
          onClose={this.handleClose}
          anchorEl={this.popperNode}
          transitionDuration={0}
          classes={{
            paper: classes.paper,
          }}
          PaperProps={{ square: true }}
        >
          <TextField
            autoFocus
            fullWidth
            value={inputValue}
            onChange={this._handleInputChange}
            placeholder={IntlUtil.label(PortalLabels.SEARCH)}
            InputProps={{
              style: { padding: `7px 15px`, boxSizing: 'border-box' },
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              autoComplete: 'off',
            }}
          />

          <div
            className={classNames(classes.itemsContainer, classes.fadeOverlay, {
              [classes.fadeOverlayDisable]: scrolledToEnd,
            })}
            ref={domeElement => {
              this.scrollFade.current = domeElement
              if (domeElement) this.handleScroll()
            }}
            onScroll={this.handleScroll}
          >
            {suggestions &&
              (suggestions.length === 0 ? (
                <MenuItem>No results found</MenuItem>
              ) : (
                suggestions.map((suggestion, index) => (
                  <SuggestionItem
                    isSelected={suggestion._id === selectedAccountId}
                    onClick={this._handleSuggestionClick(suggestion)}
                    key={index}
                    suggestion={suggestion}
                    index={index}
                  />
                ))
              ))}
          </div>
        </Popover>
      </React.Fragment>
    )
  }
}

const suggestionStyles = ({ spacing: { unit }, palette }) => ({
  root: {
    color: palette.grey500,
    whiteSpace: 'normal',
    '&:hover': {
      color: palette.accent300.contrastText,
      backgroundColor: palette.accent300.main,
    },
    paddingLeft: unit * 3,
  },

  group: {
    fontWeight: 'bold',
    paddingLeft: 16,
    color: palette.accent200.contrastText,
    backgroundColor: palette.accent200.main,
    pointerEvents: 'none',
  },

  merged: {
    fontWeight: 'bold',
    paddingLeft: 16,
  },

  selected: {
    color: palette.accent200.contrastText,
    backgroundColor: palette.accent200.main,
    '&:hover': {
      color: palette.accent400.contrastText,
      backgroundColor: palette.accent400.main,
    },
  },

  inactive: {
    opacity: 0.5,
  },
})

const SuggestionItem = withStyles(suggestionStyles)(({
  classes,
  suggestion,
  isSelected,
  ...rest
}) => {
  const { accountName, accountStatus, _id, name, merged } = suggestion

  const isGroup = !!name && !merged
  const isMerged = !!name && merged

  return (
    <MenuItem
      value={_id}
      className={classNames(
        classes.root,
        isMerged && classes.merged,
        isGroup && classes.group,
        isSelected && classes.selected,
        accountStatus !== 'active' && classes.inactive,
      )}
      {...rest}
    >
      {merged === true || !!name ? name : accountName}
    </MenuItem>
  )
})

const AccountsGridView = props => {
  const { accounts, selectedAccountId, onAccountChange, onMoreClicked } = props

  const _handleAccountMoreClick = (e, account) => {
    e.stopPropagation()
    onMoreClicked && onMoreClicked(account)
  }

  return (
    <div className="flex gap-4 overflow-scroll md:grid md:grid-cols-3">
      {accounts.map(account => {
        const { _id, accountName, address } = account
        const isSelected = _id === selectedAccountId
        return (
          <>
            <Paper
              onClick={() => onAccountChange && onAccountChange(_id)}
              elevation={0}
              className={classNames(
                'border-grey-200 !rounded-outer relative flex min-w-[60%] flex-1 cursor-pointer items-start justify-start border p-6 pl-4 pr-4 md:w-full',
                {
                  ['!border-accent-400']: isSelected,
                },
              )}
            >
              <div className="flex w-full flex-col items-start justify-between gap-y-3">
                <Typography variant="h6">{accountName}</Typography>
                <Typography variant="body2">{address}</Typography>
              </div>
              <div className={'absolute right-3 top-3'}>
                <IconButton onClick={e => _handleAccountMoreClick(e, account)}>
                  <Icon>more_vert</Icon>
                </IconButton>
              </div>
            </Paper>
          </>
        )
      })}
    </div>
    // <div className="grid row-auto md:grid-cols-3 gap-4 ">
    //   {accounts.map((account) => {
    //     const { _id, accountName, address } = account
    //     console.log('account', account)
    //     const isSelected = _id === selectedAccountId
    //     return (
    //       <Paper
    //         elevation={0}
    //         className="flex w-full flex-col items-start justify-center rounded border p-4"
    //       >
    //         <div className="flex w-full items-center justify-between">
    //           <Typography variant="h6">{accountName}</Typography>
    //           <Icon>more_vert</Icon>
    //         </div>
    //         <div>
    //           <Typography variant="body2">
    //             {address}
    //           </Typography>
    //         </div>
    //       </Paper>
    //     )
    //   })}
    //
    //   <Paper
    //     elevation={0}
    //     className="flex w-full flex-col items-start justify-center rounded border p-4"
    //   >
    //     <div className="flex w-full items-center justify-between">
    //       <Typography variant="h6">{'accountName'}</Typography>
    //       <Icon>more_vert</Icon>
    //     </div>
    //     <div>
    //       <Typography variant="body2">
    //         {'address'}
    //       </Typography>
    //     </div>
    //   </Paper>
    //
    //   <Paper
    //     elevation={0}
    //     className="flex w-full flex-col items-start justify-center rounded border p-4"
    //   >
    //     <div className="flex w-full items-center justify-between">
    //       <Typography variant="h6">{'accountName'}</Typography>
    //       <Icon>more_vert</Icon>
    //     </div>
    //     <div>
    //       <Typography variant="body2">
    //         {'address'}
    //       </Typography>
    //     </div>
    //   </Paper>
    // </div>
  )
}

export { AccountsGridView }
export default withAccount(withStyles(styles)(HeaderItemDropDown))
