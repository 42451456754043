import { useMember, usePageData, usePopupData } from 'shared-ui/components/pages/PageWrapper'
import { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useWebsocket } from '~components/WebsocketEvents'
import useEvent from 'react-use-event-hook'
import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'

function PageRetention({ location, history }) {
  const pageData = usePageData()
  const popupData = usePopupData()
  const member = useMember()
  const { socket } = useWebsocket()

  const pageVisitTimeout = useRef()
  const triggerRetentionTimeout = useRef()

  const pseudoRouteId = location.state?.pseudoRoute?._id

  const onRouteChanged = useEvent((routeId, data) => {
    if (!socket) return
    if (!member?._id) return
    if (!pageData) return

    AnalyticsEvents.dataPush(member, data)

    const { props: { _id, elements, _link: { trigger } = {} } = {} } = data
    let triggerRetentionTime

    if (_id === routeId) {
      triggerRetentionTime = trigger?.retentionTime
    } else if (elements) {
      const childRoute = elements.find(({ _id }) => _id === pseudoRouteId)
      if (childRoute) {
        const { _link: { trigger } = {} } = childRoute
        triggerRetentionTime = trigger?.retentionTime
      }
    }

    const memberId = member._id.toString()

    if (triggerRetentionTime !== undefined) {
      triggerRetentionTimeout.current = setTimeout(
        () => socket.emit('pageRetention', { routeId, memberId }),
        triggerRetentionTime * 1000,
      )
    }

    pageVisitTimeout.current = setTimeout(
      () => socket.emit('pageVisit', { routeId, memberId }),
      2000, //currently hard-coded to 2 secs
    )
  })

  useEffect(() => {
    let routeId = pageData?.props?._id
    if (!routeId) return
    onRouteChanged(routeId, pageData)
  }, [onRouteChanged, pageData])

  useEffect(() => {
    if (!pseudoRouteId || history.action === 'POP') return
    onRouteChanged(pseudoRouteId, pageData)
  }, [onRouteChanged, pseudoRouteId, history.action, pageData])

  useEffect(() => {
    let popupId = popupData?.props?._id
    if (!popupId) return
    onRouteChanged(popupId, popupData)
  }, [onRouteChanged, popupData])

  return null
}

export default withRouter(PageRetention)
