import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import FooterItem from '~components/atoms/FooterItem'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'

const components = {
  FooterItem,
}

const Footer = ({ className, data: { items = [] }, captchaBrandingVisibility }) => {
  return (
    <Toolbar disableGutters className={`border-grey-200 bg-paper border-t p-4 ${className}`}>
      <div className="flex w-full flex-col-reverse gap-4 sm:flex-row sm:items-start sm:justify-between">
        <div className="text-center text-xs sm:text-left">
          <Typography variant="caption" className="!text-grey-400">
            {IntlUtil.label(PortalLabels.FOOTER_COPYRIGHT)}
          </Typography>
        </div>

        <div
          className={`flex w-full flex-col items-center gap-0 sm:w-auto sm:items-end ${captchaBrandingVisibility ? 'mt-[-8px] sm:mt-0' : ''}`}
        >
          {captchaBrandingVisibility && (
            <div className="text-grey-400 mr-4 text-center text-xs sm:text-right">
              <Typography variant="caption" className="!text-grey-400">
                This site is protected by reCAPTCHA and the Google{' '}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-grey-500 hover:underline"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-grey-500 hover:underline"
                >
                  Terms of Service
                </a>{' '}
                apply.
              </Typography>
            </div>
          )}

          <div className="flex w-full flex-wrap justify-center gap-2 sm:w-auto sm:justify-end sm:gap-4">
            {items.map(({ props, component }, index) => {
              const ItemComponent = components[component]
              return (
                <ItemComponent key={'item_' + index} {...props} className="text-grey-500 text-sm" />
              )
            })}
          </div>
        </div>
      </div>
    </Toolbar>
  )
}

export default Footer
