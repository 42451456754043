import React, { useState, useEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'
import Lottie from 'react-lottie'
import { useWebsocket } from '~components/WebsocketEvents'
import { useThemeUpdate } from 'shared-ui/assets/styles/PortalThemeProvider'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import { getPointsLabel } from 'shared-ui/utils/points'
import { useMember } from 'shared-ui/components/pages/PageWrapper'

const earnedSound = '/sounds/earned.mp3'
const burnedSound = '/sounds/burned.mp3'

const PointsAnimation = ({ enableSound }) => {
  const { jpointsChanges, setJpointsChanges } = useWebsocket()
  const [currentAnimation, setCurrentAnimation] = useState(null)
  const { colorScheme } = useThemeUpdate()
  const [lottieJson, setLottieJson] = useState(null)
  const [lastColorScheme, setLastColorScheme] = useState(colorScheme)
  const member = useMember()
  const audioRef = useRef(null)
  const soundTimeoutRef = useRef(null) // Ref to track delayed sound timeout

  // Process queued differences
  useEffect(() => {
    if (jpointsChanges.length > 0 && !currentAnimation) {
      setCurrentAnimation(jpointsChanges[0])
      setJpointsChanges(prev => prev.slice(1))
    }
  }, [jpointsChanges, currentAnimation, setJpointsChanges, member?.selectedAccountId])

  useEffect(() => {
    if (currentAnimation) {
      const timer = setTimeout(() => {
        setCurrentAnimation(null)
      }, 2000)

      return () => clearTimeout(timer)
    }
  }, [currentAnimation])


  useEffect(() => {
    setCurrentAnimation(null)
    setLottieJson(null)

    // Immediately clear any pending sound
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      audioRef.current = null
    }

    // Clear pending sound timeout if any
    if (soundTimeoutRef.current) {
      clearTimeout(soundTimeoutRef.current)
      soundTimeoutRef.current = null
    }
  }, [member?.selectedAccountId])

  // Dynamically load the appropriate Lottie JSON.
  useEffect(() => {
    if (!currentAnimation && colorScheme !== lastColorScheme) return

    const loadAnimation = async () => {
      const isNegative = currentAnimation?.difference < 0
      const animationFile = isNegative
        ? colorScheme === 'dark'
          ? import('./lottie/BurnedDark.json')
          : import('./lottie/Burned.json')
        : colorScheme === 'dark'
          ? import('./lottie/EarnedDark.json')
          : import('./lottie/Earned.json')

      const module = await animationFile
      setLottieJson(module.default)
    }

    loadAnimation()
  }, [currentAnimation])

  useEffect(() => {
    setLastColorScheme(colorScheme)
  }, [colorScheme])

  const color = useMemo(() => currentAnimation?.color, [currentAnimation])

  const label = useMemo(() => {
    if (!currentAnimation) return ''
    return IntlUtil.label(getPointsLabel(currentAnimation.id))
  }, [currentAnimation])

  const updatedLottieJson = useMemo(() => {
    if (!lottieJson || !currentAnimation) return null
    const updatedJson = JSON.parse(JSON.stringify(lottieJson))

    updatedJson.layers.forEach(layer => {
      if (layer.ty === 5) {
        const textKeyframe = layer.t?.d?.k?.[0]
        if (textKeyframe?.s) {
          textKeyframe.s.t = `${currentAnimation?.difference}\u00A0${label}`

          const [r, g, b] = color.match(/\w\w/g).map(hex => parseInt(hex, 16) / 255)
          textKeyframe.s.fc = [r, g, b]
        }
      }
    })
    return updatedJson
  }, [currentAnimation, color, label, lottieJson])

  useEffect(() => {
    if (!currentAnimation || !lottieJson || !enableSound) return

    const isNegative = currentAnimation.difference < 0
    const soundFile = isNegative ? burnedSound : earnedSound
    const audio = new Audio(soundFile)
    audioRef.current = audio

    if (isNegative) {
      // Play immediately for burned sound
      audio.play().catch(err => console.warn('Audio autoplay blocked:', err))
    } else {
      // Delay for 1s before playing the earning sound
      soundTimeoutRef.current = setTimeout(() => {
        audio.play().catch(err => console.warn('Audio autoplay blocked:', err))
      }, 1000)
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
        audioRef.current = null
      }

      // Clear pending sound timeout if any
      if (soundTimeoutRef.current) {
        clearTimeout(soundTimeoutRef.current)
        soundTimeoutRef.current = null
      }
    }
  }, [currentAnimation, lottieJson, enableSound, member?.selectedAccountId])

  if (!currentAnimation || !lottieJson) return null

  return ReactDOM.createPortal(
    <div className="pointer-events-none fixed inset-0 z-[2147483647] flex h-screen w-full items-center justify-center">
      <Lottie
        options={{ loop: false, autoplay: true, animationData: updatedLottieJson }}
        width="100%"
        height="100%"
      />
    </div>,
    document.body,
  )

}

export default PointsAnimation
