import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Icon from '@material-ui/core/Icon'
import Grid from '@material-ui/core/Grid'
import PropertyElement from '~components/molecules/PropertyElement'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import BoxWithIcon from '~components/organisms/BoxWithIcon'
import SettingEditor from '~components/organisms/SettingsBox/SettingEditor'
import PendingButton from 'shared-ui/components/atoms/PendingButton/PendingButton'
import PortalLabels from '~src/constants/PortalLabels'
import IntlUtil from 'shared-ui/utils/IntlUtil'

const actions = {
  TOGGLE: 'toggle',
  SAVE: 'save',
  CUSTOM: 'custom',
}

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  root: {
    transition: `opacity 350ms`,
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  buttonsContainer: {
    height: '20%',
    '& > *': {
      display: 'inline-block',
    },
    marginLeft: unit * 2,
    marginRight: unit * 2,
    paddingTop: unit * 2,
    paddingBottom: unit * 1.5,
    [breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > button': {
        marginLeft: unit,
        marginBottom: unit,
      },
    },
  },
  button: {
    marginBottom: unit,
    '&:not(:first-child)': {
      marginLeft: unit,
    },
  },
  actionButton: {
    color: palette.grey400,
    backgroundColor: palette.grey100,
  },
  element: {
    padding: unit + 4,
    width: '100%',

    [breakpoints.down('xs')]: {
      padding: unit,
    },
  },
  editingElement: {
    padding: unit * 2,
    paddingTop: unit * 2,
    paddingBottom: 0,
    width: '100%',
  },
  subheading: {
    paddingTop: unit * 2,
    paddingBottom: unit * 2,
    marginLeft: unit + 4,
    marginRight: unit + 4,
    color: palette.grey500,
    fontWeight: 500,
    [breakpoints.down('xs')]: {
      marginLeft: unit,
      marginRight: unit * 2,
    },

    '& + $infoLabel': {
      marginTop: -unit * 2,
    },
  },

  infoLabel: {
    display: 'inline-flex',
    marginLeft: unit * 2,
    marginRight: unit * 3,
    paddingBottom: unit,
    color: palette.grey400,
  },

  flexRow: {
    overflow: 'auto',
    flexGrow: 1,
    [breakpoints.down('xs')]: {
      overflow: 'auto',
    },
  },
  column: {
    position: 'relative',
  },
  actionBtn: { float: 'left', display: 'flex', color: palette.grey400 },
})

class SettingsBox extends React.Component {
  _elementRefs = {}

  render() {
    const {
      name,
      infoLabel,
      readOnly,
      elements,
      classes,
      className,
      disabled,
      editMode,
      editIcon,
      pending,
      action,
      elevation,
      columns = 1,
      renderExtraColumn,
      renderExtraRow,
      elementClassName,
    } = this.props

    const renderFields = () => (
      <React.Fragment>
        {elements &&
          elements.map((element, index) =>
            // TODO: Do not filter on the client. Remove disabled items from backend payload.
            {
              return (
                element.enabled &&
                element.editData?.length > 0 && (
                  <PropertyElement
                    key={element.id}
                    innerRef={ref => {
                      this._elementRefs[element.id] = ref
                    }}
                    editMode={editMode}
                    className={
                      editMode ? classes.editingElement : classes.element + ` ${elementClassName}`
                    }
                    topLine={index !== 0}
                    Editor={SettingEditor}
                    {...element}
                  />
                )
              )
            },
          )}
      </React.Fragment>
    )

    const columnsRenders = new Array(columns).fill(renderExtraColumn)
    columnsRenders[0] = renderFields

    const renderColumns = () => (
      <Grid container className={classes.flexRow}>
        {columnsRenders.map(
          (r, index) =>
            r && (
              <Grid item key={index} className={classes.column + ' w-full'}>
                {r && r()}
                {renderExtraRow && renderExtraRow()}
              </Grid>
            ),
        )}
      </Grid>
    )

    return (
      <BoxWithIcon
        elevation={elevation}
        className={classNames(className, classes.root, { [classes.disabled]: disabled || pending })}
        onIconClick={this._handleIconClick}
        ariaLabel="Edit"
        icon={!readOnly && !editMode ? 'edit' : undefined}
      >
        {/* HEADING */}
        {name && (
          <Typography variant="h6" className={classes.subheading}>
            {name}
          </Typography>
        )}

        {infoLabel && (
          <Typography variant="subtitle1" className={classes.infoLabel}>
            <Icon style={{ marginRight: 4 }}>info</Icon>
            {infoLabel}
          </Typography>
        )}

        {/* ITEMS */}
        {renderColumns()}

        {/* BUTTONS */}
        {editMode && (
          <div className={classes.buttonsContainer} align="right">
            {action && (
              <PendingButton
                className={classes.actionBtn}
                variant="text"
                onClick={() => this._handleCustomAction(action)}
              >
                {editIcon}
                {action.name}
              </PendingButton>
            )}
            {/*<div align="right">*/}
            <Button variant="outlined" className={classes.button} onClick={this._handleCancelClick}>
              {IntlUtil.label(PortalLabels.CANCEL)}
            </Button>
            <PendingButton
              pending={pending}
              className={`${classes.button} flex items-center justify-center`}
              variant="contained"
              onClick={this._handleSaveClick}
            >
              {IntlUtil.label(PortalLabels.SAVE)}
            </PendingButton>
            {/*</div>*/}
          </div>
        )}
      </BoxWithIcon>
    )
  }

  setExternalErrors = externalErrors => {
    for (let id in this._elementRefs) {
      const element = this._elementRefs[id]

      const editor = element && element.editorRef
      editor && editor.setExternalErrors(externalErrors)
    }
  }

  _handleCancelClick = () => {
    const { onAction } = this.props

    onAction && onAction(actions.TOGGLE, undefined)
  }

  _handleSaveClick = () => {
    let data = {}

    for (let id in this._elementRefs) {
      const elements = this._elementRefs[id]
      const values = elements.getValues()

      if (!values) return

      data = { ...data, ...values }
    }

    const { onAction } = this.props
    onAction && onAction(actions.SAVE, data)
  }

  _handleIconClick = () => {
    const { disabled, onAction, id } = this.props
    if (disabled) {
      return
    }
    onAction && onAction(actions.TOGGLE, id)
  }

  _handleCustomAction = actionData => {
    const { onAction } = this.props
    onAction && onAction(actions.CUSTOM, actionData)
  }
}

export default withStyles(styles)(SettingsBox)
export { actions }
