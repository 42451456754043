import React, { useEffect, useRef, useState } from 'react'
import Header from '~components/organisms/Header'
import Footer from '~components/organisms/Footer'
import MainMenu from '~components/organisms/MainMenu'

import IconButton from '@material-ui/core/IconButton'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageProgress from 'shared-ui/components/PageProgress'
import Icon from '@material-ui/core/Icon'
import { AccountProvider } from '~src/AccountContext'
import { AppFrameProvider, withAppFrame } from 'shared-ui/components/AppFrameContext'
import { useApi } from 'shared-ui/api/ApiContext'
import { isPrintMode, useMember, usePageData } from 'shared-ui/components/pages/PageWrapper'
import Chatbot from 'shared-ui/components/molecules/Chatbot'
import WebsocketEvents from '~components/WebsocketEvents'
import PageRetention from '~components/PageRetention'
import GoogleServices from '~components/GoogleServices'
import ReCaptcha from 'shared-ui/components/ReCaptcha'
import IntlStyleFixes from '~src/IntlStyleFixes'
import classnames from 'classnames'
import FooterNavigation from '~components/organisms/FooterNavigation'
import * as footerRef from 'immer'
import useDeviceType from 'shared-ui/utils/useDeviceType'
import PointsAnimation from '~components/PointsAnimation/PointsAnimation'

function AppFrame(props) {
  const api = useApi()
  const { appMode, componentName, appFrame = {} } = usePageData()
  const {
    header,
    menu,
    apps,
    footer,
    footerNavigation,
    settings: { languages, general, technical: { captchaVisibility } = {} } = {},
  } = appFrame
  const member = useMember()
  const hasFooterNav = !!(footerNavigation?.items?.length)
  const handleAccountChange = selectedAccountId => {
    api.changeSelectedAccount({ selectedAccountId })
  }

  const [footerTop, setFooterTop] = useState(null)
  const footerRef = useRef(null)
  const { isMobile } = useDeviceType()

  useEffect(() => {
    const updateFooterPosition = () => {
      if (footerRef.current) {
        const footerPosition = footerRef.current.getBoundingClientRect().top
        setFooterTop(footerPosition)
      }
    }

    updateFooterPosition()
  }, [footerRef])
  const isPrint = isPrintMode()
  const { children, busy, routeKey, contentKey } = props
  const { accounts, selectedAccountId } = member || {}

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const headerRef = useRef(null)
  const [isHeaderSticky, setIsHeaderSticky] = useState(false)

  const isRewardsPage = props.children[1]?.props.id === 'rewards'

  useEffect(() => {
    if (!isRewardsPage || isMobile) return

    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const activationPoint = 64

      if (scrollPosition >= activationPoint) {
        setIsHeaderSticky(true)
      } else {
        setIsHeaderSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Initial check on mount

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isRewardsPage, isMobile])

  const toggleMobileMenu = () => {
    setShowMobileMenu(prev => !prev)
  }

  const closeMobileMenu = () => {
    setShowMobileMenu(false)
  }
  let xsMenuItems = []
  const { header: { items = [] } = {} } = appFrame
  items.forEach(item => {
    if (!item.props.xsIconVisible) {
      xsMenuItems.push({ ...item.props, _link: item._link })
    }
  })

  const {
    displayHealthWarning,
    healthWarningFooter,
    healthWarningSize,
    enableAnimations,
    enableAnimationsSounds,
  } = general || {}
  document.documentElement.style.setProperty('--health-warning-size', `${healthWarningSize}vh`)

  return (
    <AppFrameProvider value={appFrame}>
      <AccountProvider
        value={{ accounts, selectedAccountId, onAccountChange: handleAccountChange }}
      >
        <Chatbot />
        <GoogleServices />
        <ReCaptcha />
        <IntlStyleFixes />

        <WebsocketEvents>
          {enableAnimations && <PointsAnimation enableSound={enableAnimationsSounds} />}
          <PageRetention />
          <div
            className={classnames(
              'bg-paper relative z-0 flex min-h-screen w-full flex-col flex-wrap overflow-auto print:block',
            )}
            data-template-id={componentName}
          >
            <PageProgress visible={busy} className="!absolute z-[9999] w-full" />

            {!isPrint && header && (
              <div
                ref={headerRef}
                className={`w-full transition-all duration-300 ${
                  isRewardsPage && !isMobile && isHeaderSticky ? 'fixed left-0 top-0 z-50' : ''
                }`}
              >
                <Header
                  id="header"
                  member={member}
                  languages={languages}
                  data={header}
                  mode={appMode}
                  renderDrawerIcon={() =>
                    hasFooterNav ? (
                      <div
                        className="z-1 hidden h-full w-16 items-center justify-center md:flex"
                        align="center"
                      >
                        {!!menu && (
                          <IconButton
                            aria-label="Open drawer"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                          >
                            <Icon>menu</Icon>
                          </IconButton>
                        )}
                      </div>
                    ) : (
                      <div
                        className="z-1 flex h-full w-16 items-center justify-center"
                        align="center"
                      >
                        {!!menu && (
                          <IconButton
                            aria-label="Open drawer"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                          >
                            <Icon>menu</Icon>
                          </IconButton>
                        )}
                      </div>
                    )
                  }
                />
              </div>
            )}
            <div
              className={`print:bg-paper flex max-h-full w-full flex-grow grow flex-col ${
                displayHealthWarning && healthWarningFooter
                  ? 'mb-bottom-health-mobile sm:mb-bottom-health'
                  : ''
              }`}
            >
              <TransitionGroup className={'flex-grow'}>
                <CSSTransition
                  key={routeKey}
                  timeout={{ enter: 450, exit: 0 }}
                  style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  classNames={{
                    enter: 'opacity-0',
                    enterActive: 'opacity-100 transition-opacity ease-out duration-200 delay-100',
                  }}
                >
                  <div key={contentKey}>{children}</div>
                </CSSTransition>
              </TransitionGroup>

              {!isPrint && footer && (
                <Footer
                  className="w-full"
                  data={footer}
                  captchaBrandingVisibility={captchaVisibility}
                />
              )}
              {hasFooterNav && isMobile && (
                <FooterNavigation
                  ref={footerRef}
                  items={footerNavigation.items}
                  showMobileMenu={showMobileMenu}
                  toggleMobileMenu={toggleMobileMenu}
                  onFooterPosition={pos => setFooterTop(pos)}
                />
              )}
            </div>
            {!isPrint && menu && (
              <div className="h-full">
                <MainMenu
                  showMobileMenu={showMobileMenu}
                  onCloseMenu={closeMobileMenu}
                  open={isMenuOpen}
                  data={menu}
                  apps={apps}
                  member={member}
                  xsMenuItems={xsMenuItems}
                  className="h-full"
                  onOverlayClick={() => {
                    setIsMenuOpen(false)
                    setShowMobileMenu(false)
                  }}
                  api={api}
                  footerNavigation={footerNavigation}
                  footerTop={footerTop}
                />
              </div>
            )}
          </div>
        </WebsocketEvents>
      </AccountProvider>
    </AppFrameProvider>
  )
}

export default AppFrame

export { withAppFrame }
