import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Icon from '@material-ui/core/Icon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import PageData from 'shared-ui/utils/PageData'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { withRouter } from 'react-router-dom'
import Link from 'shared-ui/components/Link'
import { ReactComponent as Logo } from 'shared-ui/assets/img/logo-black.svg'

import Constant from 'shared-ui/utils/Contants'

import PortalLabels from '~src/constants/PortalLabels'
import IntlUtil from 'shared-ui/utils/IntlUtil'

import { getColorByType, getPointsLabel } from 'shared-ui/utils/points'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  item: {},
  itemSelected: {
    backgroundColor: palette.accent200.main + ' !important',
  },
  nestedItem: {
    paddingLeft: unit * 3 + 'px !important',
  },
  menuIconSelected: {
    color: palette.primary.main,
  },
  subDivider: {
    marginLeft: unit * 2,
  },
  itemText: {
    paddingLeft: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    fontSize: '13px',
  },
  itemTextContainer: {
    paddingLeft: 0,
  },
  drawerPaper: {
    position: 'inherit',
    width: Constant.drawerWidth,
    height: '100%',
  },
  xsOnly: {
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  platformIcon: {
    maxHeight: unit * 5,
    padding: '6px 0 6px',
    maxWidth: '100%',
    [breakpoints.down('xs')]: {
      maxHeight: unit * 4,
    },
  },
  mobilePoints: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobilePointsContainer: {
    backgroundColor: palette.grey100,
  },
  menuPaper: {
    top: '0px !important',
  },
})

const MainMenu = withStyles(styles)(
  class extends React.Component {
    constructor(props) {
      super(props)

      const activeMenuItem = this.props.data.find(menuItem => {
        return (
          menuItem.items &&
          menuItem.items.find(item => window.location.pathname.startsWith(item.to))
        )
      })

      this.state = {
        dynamicMaxHeight: null,
        menuTop: 0,
      }

      if (activeMenuItem) {
        this.state[activeMenuItem.label] = true
      }
    }

    static defaultProps() {
      return {
        data: [],
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.showMobileMenu !== prevProps.showMobileMenu) {
        const visibleViewportTop = window.scrollY || document.documentElement.scrollTop
        this.setState({ menuTop: visibleViewportTop })

        if (this.props.showMobileMenu) {
          document.body.classList.add('overflow-hidden')
        } else {
          document.body.classList.remove('overflow-hidden')
        }
      }
    }

    onLinkClicked = e => {
      const { onOverlayClick } = this.props
      onOverlayClick && onOverlayClick(e)
    }

    // same as before
    renderMenuItem = (menuItem, index, xsOnly = false) => {
      const { classes } = this.props
      let isMenuActive = false

      menuItem._link = menuItem._link || {}

      const to = menuItem._link.delegateTo ? menuItem._link.delegateTo : menuItem.to
      const target = menuItem._link.delegateTo ? '_blank' : undefined

      const createSubMenu = menuItem => {
        if (menuItem.items) {
          return (
            <Collapse in={this.state[menuItem.label]} timeout="auto" unmountOnExit>
              <Divider className={classes.subDivider} />
              {menuItem.items.map((item, k) => {
                let isActive = window.location.pathname.startsWith(item.to)
                isMenuActive = isMenuActive || isActive

                item._link = item._link || {}
                const to = item._link.delegateTo ? item._link.delegateTo : item.to
                const target = item._link.delegateTo ? '_blank' : undefined

                return (
                  <ListItem
                    key={k}
                    component={item._link.delegateTo ? 'a' : Link}
                    to={to}
                    href={to}
                    target={target}
                    button
                    selected={isActive}
                    className={classNames({
                      [classes.itemSelected]: isActive,
                    })}
                    onClick={this.onLinkClicked}
                  >
                    <ListItemText
                      className={classes.nestedItem}
                      primary={item.label}
                      primaryTypographyProps={{ className: classes.itemText }}
                    />
                  </ListItem>
                )
              })}
              <Divider className={classes.subDivider} />
            </Collapse>
          )
        }
      }

      const subMenu = createSubMenu(menuItem)

      return (
        <div key={index + (xsOnly ? '_xs' : '')}>
          <MenuItem
            className={classNames(classes.item, { [classes.xsOnly]: xsOnly })}
            button
            component={menuItem.items ? undefined : menuItem._link.delegateTo ? 'a' : Link}
            to={to || '#'}
            href={to || '#'}
            target={target}
            onClick={this.handleClick(menuItem)}
          >
            <ListItemIcon>
              <Icon className={classNames({ [classes.menuIconSelected]: isMenuActive })}>
                {menuItem.icon}
              </Icon>
            </ListItemIcon>
            <ListItemText
              inset
              primary={menuItem.label}
              className={classes.itemTextContainer}
              primaryTypographyProps={{ className: classes.itemText }}
            />
            {menuItem.items ? <Icon>{this.state[menuItem.label] ? 'less' : 'more'}</Icon> : null}
          </MenuItem>

          {subMenu}
        </div>
      )
    }

    handleClick =
      ({ items, label }) =>
      e => {
        if (!items) {
          this.onLinkClicked(e)
        }
        this.setState({ [label]: !this.state[label] })
      }

    render() {
      const {
        classes,
        data = [],
        xsMenuItems = [],
        open,
        className,
        onOverlayClick,
        apps,
        member,
        footerNavigation,
        showMobileMenu,
      } = this.props
      let {
        settings: { general: { platformIcon, healthWarningSize, displayHealthWarning } = {} } = {},
      } = PageData.appFrame || {}
      const { jpoints = [] } = member

      const filteredData = footerNavigation?.items
        ? data.filter(
            item => !footerNavigation?.items?.some(footerItem => footerItem.props?.to === item.to),
          )
        : data
      const filteredXsMenuItems = xsMenuItems?.filter(
        item =>
          item.to !== undefined &&
          !footerNavigation?.items?.some(footerItem => footerItem.props?.to === item.to),
      )
      const getDrawerContent = (useFiltered = false) => {
        const contentData = useFiltered ? filteredData : data
        const xsMenuItemsData = useFiltered ? filteredXsMenuItems : xsMenuItems

        return (
          <>
            <Toolbar>
              {platformIcon ? (
                <img src={platformIcon.path} alt="Platform Icon" className={classes.platformIcon} />
              ) : (
                <Logo style={{ alignSelf: 'flex-start' }} />
              )}
            </Toolbar>
            <Divider />
            <List>
              <Hidden smUp>
                {jpoints.length > 0 && (
                  <>
                    <ListItem className={classes.mobilePointsContainer}>
                      <div className={classes.mobilePoints}>
                        {jpoints.map(item => (
                          <Typography variant="subtitle1" key={item.id}>
                            <span
                              style={{
                                fontWeight: 'bold',
                                color: getColorByType(jpoints, item.type),
                              }}
                            >
                              {IntlUtil.num(item.amount)}{' '}
                            </span>
                            {IntlUtil.label(getPointsLabel(item.type))}
                          </Typography>
                        ))}
                      </div>
                    </ListItem>
                    <Divider />
                  </>
                )}
              </Hidden>
              {[
                ...xsMenuItemsData.map((item, index) => this.renderMenuItem(item, index, true)),
                <Divider className={classes.xsOnly} key="divider" />,
                ...contentData.map((item, index) => this.renderMenuItem(item, index)),
                <ListItem
                  key="logout"
                  button
                  onClick={() => {
                    const { api } = this.props
                    api.logout()
                  }}
                >
                  <ListItemIcon>
                    <Icon>{'exit_to_app'}</Icon>
                  </ListItemIcon>
                  <ListItemText
                    inset
                    className={classes.itemTextContainer}
                    primary={IntlUtil.label(PortalLabels.LOG_OUT)}
                    primaryTypographyProps={{ className: classes.itemText }}
                  />
                </ListItem>,
              ]}
            </List>
            <Divider />
            {apps &&
              apps.length > 0 &&
              apps.map((a, idx) => (
                <a href={a.to} target="_blank" rel="noreferrer" key={idx}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon>{a.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText
                      inset
                      className={classes.itemTextContainer}
                      primary={a.name}
                      primaryTypographyProps={{ className: classes.itemText }}
                    />
                  </ListItem>
                </a>
              ))}
          </>
        )
      }

      return (
        <>
          <Drawer
            className={className}
            classes={{ paper: classes.drawerPaper }}
            anchor="left"
            open={open}
            onClose={onOverlayClick}
          >
            {getDrawerContent(false)}
          </Drawer>

          <CSSTransition
            in={showMobileMenu}
            timeout={300}
            classNames={{
              enter: 'transform -translate-y-4 opacity-0',
              enterActive:
                'transform translate-y-0 opacity-100 transition-all duration-300 ease-out',
              exit: 'transform translate-y-0 opacity-100',
              exitActive: 'transform -translate-y-4 opacity-0 transition-all duration-300 ease-in',
            }}
            unmountOnExit
          >
            <div
              id="custom-dropdown-menu"
              className="bg-paper absolute left-0 z-[99999] w-full overflow-y-auto shadow-lg ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
              tabIndex="-1"
              style={{
                top: `${this.state.menuTop}px`,
                maxHeight: `${this.props.footerTop}px`,
                height: `${this.props.footerTop}px`,
              }}
            >
              {getDrawerContent(true)}
            </div>
          </CSSTransition>
        </>
      )
    }
  },
)

export default withRouter(MainMenu)
