import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography/Typography'
import Hidden from '@material-ui/core/Hidden'
import HeaderItemTemplate from '~components/molecules/HeaderItem/template'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import classNames from 'classnames'
import {
  getBalanceLink,
  getColorByType,
  getPointsLabel,
  pushRewardsAnalytics,
} from 'shared-ui/utils/points'
import useUpdateAnimation from '~src/hooks/useUpdateAnimation'
import { Link } from 'react-router-dom'

const styles = () => ({
  root: {},
  points: {
    fontWeight: 'bold',
    lineHeight: 1.2,
    letterSpacing: 0.4,
    position: 'relative',
    wordBreak: 'keep-all',
    top: 3,
  },
})

const Item = withStyles(styles)(function (props) {
  const { classes, color, label, amount, type } = props

  const style = useUpdateAnimation(amount)

  return (
    <Hidden xsDown>
      <HeaderItemTemplate {...props} label={label}>
        <Link to={getBalanceLink('#my_balance', type)} onClick={pushRewardsAnalytics}>
          <Typography
            variant="h4"
            className={classNames(classes.points)}
            style={{ color, ...style }}
          >
            {IntlUtil.num(amount)}
          </Typography>
        </Link>
      </HeaderItemTemplate>
    </Hidden>
  )
})

function HeaderItemPoints(props) {
  const { member: { jpoints = [] } = {} } = props

  return jpoints.map((item, index) => {
    const { type, amount } = item
    const color = getColorByType(jpoints, type)
    const label = IntlUtil.label(getPointsLabel(type))

    return <Item key={index} {...props} amount={amount} color={color} label={label} type={type} />
  })
}

export default HeaderItemPoints
