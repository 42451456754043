import React from 'react'
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  warn: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [breakpoints.down('sm')]: {
      flex: 'auto',
      justifyContent: 'flex-start',
      marginRight: unit,
    },
    [breakpoints.down('xs')]: {
      flex: 'auto',
      width: '100%',
      marginRight: unit,
    },
  },
  icon: {
    marginRight: unit,
  },
  textRed: {
    color: palette.common.red,
  },
})

const HelperBox = ({
  classes,
  isProductDetail,
  isInvalid,
  orderValueMissing,
  isEmpty,
  orderValueExceeding,
  amountInDefaultValueMissing,
  labels = {},
  minOrderPrice,
  maxOrderPrice,
}) => {
  const showMinMax = minOrderPrice > 0 && maxOrderPrice > 0

  if (isProductDetail) {
    return null
  }

  return (
    <div className={classNames(classes.warn, classes.messageContainer)}>
      {showMinMax && (
        <Icon className={classNames(classes.icon, { [classes.textRed]: isInvalid })}>
          error_outline
        </Icon>
      )}
      <Typography className={isInvalid ? classes.textRed : null}>
        {showMinMax &&
          !isInvalid &&
          labels.minMaxWarn
            .replace('%1s', IntlUtil.price(minOrderPrice))
            .replace('%2s', IntlUtil.price(maxOrderPrice))}
        {!isEmpty &&
          orderValueMissing > 0 &&
          labels.minWarn.replace('%s', IntlUtil.price(orderValueMissing))}
        {!isEmpty &&
          orderValueExceeding > 0 &&
          labels.maxWarn.replace('%s', IntlUtil.price(orderValueExceeding))}
      </Typography>
      <Typography className={isInvalid ? classes.textRed : null}>
        {!isEmpty &&
          amountInDefaultValueMissing > 0 &&
          labels.minQtyWarn.replace('%s', IntlUtil.num(amountInDefaultValueMissing))}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(HelperBox)
