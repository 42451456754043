import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { breakLine, htmlTextAnchorParser } from 'shared-ui/utils'
import IntlUtil from 'shared-ui/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  container: {
    position: 'relative',
  },
  value: {
    color: palette.grey400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subheading: {
    color: palette.grey500,
    fontWeight: 500,
  },
  column: {
    display: 'inline-flex',
    paddingRight: unit,
  },
  valueIcon: {
    color: palette.accent400.main,
    fontWeight: 500,
    marginRight: unit * 0.5,
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  brandColor: {
    color: palette.accent400.main,
  },
})

const PropertyElement = withStyles(styles)(
  class PropertyElement extends React.Component {
    render() {
      const { editable, editData, topLine, className, classes, style, Editor, editMode } =
        this.props

      return (
        <div className={classes.container} style={style}>
          {topLine && !editMode ? <Divider /> : undefined}
          <Grid container className={className} direction={'row'}>
            {Editor ? (
              <Editor
                edit={editMode}
                elements={editable && editData}
                editorRef={ref => (this.editorRef = ref)}
                render={() => this._renderContent()}
              />
            ) : (
              this._renderContent()
            )}
          </Grid>
        </div>
      )
    }

    _renderContent = () => {
      const { classes, name, type } = this.props
      let style = type === 'tel' ? { direction: 'ltr' } : undefined

      return [
        <Grid key="name" item xs={12} sm={5} className={classes.column}>
          <Typography key="subtitle1" variant="body1" className={classes.subheading}>
            {htmlTextAnchorParser(name)}
          </Typography>
        </Grid>,
        <Grid key="value" item xs={12} sm={7} className={classes.column}>
          <Typography key="value" variant="subtitle1" className={classes.value} style={style}>
            {breakLine(this.parseValue())}
          </Typography>
        </Grid>,
      ]
    }

    parseValue = () => {
      const { value, type } = this.props
      if (value === undefined || value === null) {
        return value
      }

      if (type === 'enum') {
        const { editData: [editData] = [] } = this.props
        const { data = [] } = editData
        let selectedOption = data.find(option => option && option.value && option.value === value)
        if (selectedOption) {
          const { label } = selectedOption
          return label
        }
      }

      if (type === 'boolean') {
        return IntlUtil.label(value === true ? PortalLabels.YES : PortalLabels.NO)
      }

      if (type === 'date') {
        return IntlUtil.date(value, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      }

      if (type === 'datetime') {
        return IntlUtil.date(value, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })
      }

      if (typeof value === 'string' && value.includes('$d')) {
        let res = /(.*)\$d(\d+)(.*)/.exec(value)
        if (res) {
          return (
            res[1] +
            IntlUtil.date(parseInt(res[2], 10), {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
            }) +
            res[3]
          )
        }
      }

      if (type === 'phone') {
        return <a href={`tel:${value}`}>{value}</a>
      }
      if (type === 'email') {
        return <a href={`mailto:${value}`}>{value}</a>
      }

      return value.toString()
    }

    getValues = () => {
      if (!this.editorRef) {
        return {}
      }

      if (!this.editorRef.checkValidity()) {
        return
      }

      return this.editorRef.getValues()
    }
  },
)

export default PropertyElement
