import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const styles = ({ palette, spacing: { unit } }) => ({
  container: {
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    top: unit * 0.5,
    right: unit * 0.5,
    color: palette.grey400,
  },
  preventInput: {
    pointerEvents: 'none',
    userSelect: 'none',
    tabIndex: -1,
  },
  icon: {
    color: palette.grey400,
  },
})

const BoxWithIcon = withStyles(styles)(
  class BoxWithIcon extends React.Component {
    render() {
      const { classes, className, onIconClick, icon, ariaLabel, children, iconSize, elevation } =
        this.props

      return (
        <Paper square className={`${classes.container} ${className || ''}`} elevation={elevation}>
          {icon && (
            <IconButton
              className={`${classes.iconButton} ${!onIconClick ? classes.preventInput : ''}`}
              onClick={onIconClick}
              aria-label={ariaLabel}
            >
              <Icon style={iconSize ? { fontSize: iconSize } : undefined} className={classes.icon}>
                {icon}
              </Icon>
            </IconButton>
          )}

          {children}
        </Paper>
      )
    }
  },
)

export default BoxWithIcon
