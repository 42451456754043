import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import Link from 'shared-ui/components/Link'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'

import IntlUtil from 'shared-ui/utils/IntlUtil'
import PortalLabels from '~src/constants/PortalLabels'
import useUpdateAnimation from '~src/hooks/useUpdateAnimation'
import { Hidden } from '@material-ui/core'
import HelperBox from '~components/molecules/OrdersModule/components/HelperBox'
import { usePageData } from 'shared-ui/components/pages/PageWrapper'
import useDeviceType from 'shared-ui/utils/useDeviceType'

export const PANEL_HEIGHT = '62px'

const styles = ({ palette, breakpoints, spacing: { unit } }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${unit}px ${unit * 2}px`,
    boxSizing: 'border-box',
    background: palette.paper,
    borderTop: '1px solid rgba(0,0,0,0.2)',
    position: 'fixed',
    minHeight: PANEL_HEIGHT,
    left: 0,
    zIndex: 10,
    transition: 'bottom .5s',

    [breakpoints.down('sm')]: {
      display: 'block',
      backgroundColor: palette.grey100,
    },
    [breakpoints.down('xs')]: {
      padding: `${unit}px`,
    },
  },
  textRed: {
    color: palette.common.red,
  },
  total: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: `5px ${unit}px`,
    justifyContent: 'flex-end',
    fontSize: '1rem',
    fontWeight: 600,
    '& span + span': {
      display: 'inline-block',
      paddingLeft: '.5em',
    },

    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    [breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  quantity: {},
  buttonWrapper: {
    textAlign: 'right',
    minWidth: 'max-content',
    [breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginRight: 'auto',
    },
  },
  recommendedButton: {
    marginRight: unit * 2,
    marginLeft: unit * 2,
    width: 'max-content',
    [breakpoints.down('sm')]: {
      marginRight: 0,
      width: '100%',
      marginBottom: unit,
      marginTop: unit,
    },
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '50%',
    flexDirection: 'column',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shoppingCartButton: {
    [breakpoints.down('xs')]: {
      height: '50px',
      padding: '8px 12px',
      width: '100%',
    },
  },
  labelsAndButton: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      width: 'inherit',
    },
    [breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  },
  labels: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  textAqua: {
    color: palette.primary.main,
  },
  alignEnd: {
    justifyContent: 'flex-end',
  },
  resetBtn: {
    margin: '0 10px',
    [breakpoints.down('md')]: {
      margin: '0',
    },
  },
  resetIcon: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  spaceBetween: {
    [breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  totalOrderValue: {
    [breakpoints.down('md')]: {
      fontWeight: '800',
      fontSize: '18px',
    },
  },
  amountTaxWrapper: {
    padding: `0 ${unit}px 0  0`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    [breakpoints.down('md')]: {
      padding: '5px 0px',
    },
  },
  taxValue: {
    color: palette.grey400,
    fontSize: '14px',
    [breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  mobileHelperBox: {
    marginTop: '10px',
    marginRight: 'calc(100vw / 3)',
  },
  buttonsGroup: {
    display: 'flex',
    flexDirection: 'row',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      marginLeft: '10px',
      '& > *': {
        marginBottom: '10px',
      },
    },
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0',
      '& > *': {
        marginBottom: '0',
      },
    },
  },
})

function CheckoutPanel(props) {
  const {
    appFrame: {
      settings: {
        general: { displayHealthWarning, healthWarningFooter, healthWarningSize, footerMobile },
      },
    },
  } = usePageData()
  const {  isMobile }  = useDeviceType()
  const {
    cart: {
      totalOrderValue = 0,
      totalOrderAmount = 0,
      totalOrderVat,
      distributor: { minOrderPrice, maxOrderPrice } = {},
      amountInDefaultValueMissing,
      orderValueExceeding,
      orderValueMissing,
      isEmpty,
      products,
    } = {},
    classes,
    hidden,
    onCheckoutClicked,
    labels,
    width,
    hidePriceColumn,
    isProductDetail,
    resetProductQuantities,
    disableVAT = false,
  } = props

  const cartUnitSummary = useMemo(() => {
    const keys = products.reduce((acc, product) => {
      if (!product.selectedUnit) return acc
      return {
        ...acc,
        [product.selectedUnit]: acc[product.selectedUnit]
          ? acc[product.selectedUnit] + product.amount
          : product.amount,
      }
    }, {})

    return Object.keys(keys)
      .sort((a, b) => keys[b] - keys[a])
      .slice(0, 2)
      .map(key => `${keys[key]} ${key}`)
      .join(', ')
  }, [products])

  const showPrice = !hidePriceColumn && totalOrderValue !== 0

  const [panelHeight, setPanelHeight] = useState(PANEL_HEIGHT)
  const googleBadge = useRef()
  const chatbot = document.getElementById('Chatbot')

  const rootId = 'CheckoutPanel'

  function showPanel() {
    document.body.style.marginBottom = panelHeight
    const footerNavbar$ = document.getElementById('footerNavbar')
    const footerNavbarHeight = footerNavbar$ ? footerNavbar$.offsetHeight : 0
    const healthWarningPx = (healthWarningFooter && displayHealthWarning)
      ? (window.innerHeight * healthWarningSize) / 100
      : 0

    const totalTranslateY = 2 * parseInt(panelHeight, 10) + footerNavbarHeight + healthWarningPx

    if (!googleBadge.current) {
      googleBadge.current = document.querySelector('.grecaptcha-badge')
    }

    if (googleBadge.current) {
      googleBadge.current.style.transition = 'all .5s'
      googleBadge.current.style.transform = `translateY(-${totalTranslateY}px)`
    }

    if (chatbot) chatbot.style.transform = `translateY(-${totalTranslateY}px)`

    const root = document.getElementById(rootId)
    if (!root) return
    const valueMargin = footerMobile?.length && isMobile
      ? Number(healthWarningSize) + 7
      : Number(healthWarningSize)

    root.style.bottom = healthWarningFooter && displayHealthWarning
      ? `${valueMargin}vh`
      : isMobile && footerNavbarHeight > 0
        ? `${footerNavbarHeight}px`
        : '0px'

  }

  function hidePanel() {
    document.body.style.marginBottom = ''
    if (googleBadge.current) googleBadge.current.style.transform = ''
    if (chatbot) chatbot.style.transform = ''

    const root = document.getElementById(rootId)
    if (!root) return
    root.style.bottom = `-${panelHeight}`
  }

  function updateHeight() {
    const root = document.getElementById(rootId)

    if (width === 'xs' || width === 'sm') {
      setPanelHeight('0px')
      return
    }

    if (!root) return
    setPanelHeight(root.offsetHeight + 'px')
  }

  useEffect(() => {
    if (hidden) {
      hidePanel()
    } else {
      showPanel()
    }

    return () => {
      hidePanel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden])

  useLayoutEffect(() => {
    updateHeight()
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [width])

  const updateAnimation = useUpdateAnimation(totalOrderValue, { rotation: 0 })
  const isInvalid =
    !isEmpty &&
    (amountInDefaultValueMissing > 0 || orderValueExceeding > 0 || orderValueMissing > 0)

  const checkoutTotal = useMemo(
    () => (disableVAT ? totalOrderValue : totalOrderValue + totalOrderVat),
    [disableVAT, totalOrderValue, totalOrderVat],
  )

  return (
    <div id={rootId} className={classNames(classes.root, { [classes.alignEnd]: isProductDetail })}>
      <Hidden xsDown>
        <HelperBox
          isInvalid={isInvalid}
          orderValueMissing={orderValueMissing}
          isEmpty={isEmpty}
          orderValueExceeding={orderValueExceeding}
          amountInDefaultValueMissing={amountInDefaultValueMissing}
          labels={labels}
          minOrderPrice={minOrderPrice}
          maxOrderPrice={maxOrderPrice}
          isProductDetail={isProductDetail}
        />
      </Hidden>
      <div className={classes.labelsAndButton}>
        <div className={classes.labels}>
          <span className={classes.spaceBetween}>
            <Typography variant="h6" className={classNames(classes.total, classes.quantity)}>
              {labels.orderTotal}:
            </Typography>
            <span>
              {totalOrderAmount} {labels.products}
              {totalOrderAmount && cartUnitSummary ? <span>({cartUnitSummary})</span> : null}
            </span>
          </span>
          {totalOrderAmount ? <Hidden mdDown> &nbsp; | &nbsp;</Hidden> : null}
          {showPrice && (
            <div className={classes.amountTaxWrapper}>
              <Typography
                variant="h6"
                className={classNames(
                  classes.total,
                  classes.spaceBetween,
                  { [classes.textRed]: isInvalid },
                  { [classes.textAqua]: isProductDetail },
                )}
              >
                {`${IntlUtil.label(PortalLabels.AMOUNT)}: `}
                <span style={updateAnimation} className={classes.totalOrderValue}>
                  {IntlUtil.price(checkoutTotal)}
                </span>
              </Typography>
              {!disableVAT && totalOrderVat > 0 && (
                <span className={classes.taxValue}>
                  {`${IntlUtil.label(PortalLabels.INCLUDE)}: `}
                  <span>{`${IntlUtil.price(totalOrderVat)}${IntlUtil.label(
                    PortalLabels.VAT,
                  )}`}</span>
                </span>
              )}
            </div>
          )}
        </div>
        <div className={classes.buttonsGroup}>
          <Hidden xsDown>
            <Button
              variant="outlined"
              className={classes.resetBtn}
              onClick={resetProductQuantities}
            >
              <Icon className={classes.resetIcon}>replay</Icon>
            </Button>
          </Hidden>
          <div className={classes.buttonWrapper}>
            <Button
              component={Link}
              to="/shopping-cart"
              variant="contained"
              className={classes.shoppingCartButton}
              onClick={onCheckoutClicked}
            >
              {IntlUtil.label(PortalLabels.SHOPPING_CART)}
            </Button>
          </div>
        </div>
        <Hidden smUp>
          <div className={classes.mobileHelperBox}>
            <HelperBox
              isInvalid={isInvalid}
              orderValueMissing={orderValueMissing}
              isEmpty={isEmpty}
              orderValueExceeding={orderValueExceeding}
              amountInDefaultValueMissing={amountInDefaultValueMissing}
              labels={labels}
              minOrderPrice={minOrderPrice}
              maxOrderPrice={maxOrderPrice}
              isProductDetail={isProductDetail}
            />
          </div>
        </Hidden>
      </div>
    </div>
  )
}

export default withWidth()(withStyles(styles)(CheckoutPanel))
